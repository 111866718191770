import React from "react";
import styled from 'styled-components';

const HotspotImg = styled.img`
    position: absolute;
    top: ${props => props.top}%;
    left: ${props => props.left}%;
`;

const Hotspot  = ({img, top, left, onClickHotspot}) => {
    return (
        <HotspotImg
            top={top}
            left={left}
            srcSet={`${img} 2x`}
            onClick={() => onClickHotspot()}
        />
    );
};

export default Hotspot;