import React from "react";
import {Box, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({
    progress: {
        margin: '1.5rem',
    }
}));

const Loader = () => {
    const classes = useStyles();

    return (
        <Box height='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <img
                className="img-fluid"
                srcSet={"/images/logo_black.png 2x"}
                alt="logo"/>
            <CircularProgress className={classes.progress}/>
        </Box>
    );
}

export default Loader;