import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {useTranslation} from 'react-i18next';
import {Grid, Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        alignSelf: "flex-end",
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    buttonLabel: {
        paddingLeft: '.7rem',
    }
}));

export default function SimpleBottomNavigation () {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <AppBar position="sticky" color="secondary" className={classes.appBar}>
            <Toolbar>
                <Grid container justify="space-around">
                    <Grid item>
                        <IconButton edge="start" color="inherit" href="tel:+39057144511">
                            <img alt="callus" srcSet={'/images/ico_chiama@2x.png 2x'}/>
                            <Typography className={classes.buttonLabel}>{t('PageFooter.callus')}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton edge="start" color="inherit" href="mailto:info@cafenoir.it">
                            <img alt="writeus" srcSet={'/images/ico_mail@2x.png 2x'}/>
                            <Typography className={classes.buttonLabel}>{t('PageFooter.writeus')}</Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
