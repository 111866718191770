import React from "react";
import PropTypes from "prop-types";
import {makeStyles, Paper} from "@material-ui/core";
import Carousel from "./Carousel";

const useImageSliderItemStyles = makeStyles(() => ({
    root: {
        padding: '10px 0',
    },
    item: (obj) => ({
        height: 0,
        paddingTop: `${obj.ratioPercentage}%`,
        backgroundImage: `url('${obj.imgUrl}')`,
        backgroundSize: "cover",
        borderRadius: "22px"
    }),
}));
const ImageSliderItem = ({
                             imgUrl,
                             imgWidth = null,
                             imgHeight = null,
                             onClick = null
                         }) => {
    const ratioPercentage =
        imgWidth && imgHeight ? (imgHeight / imgWidth) * 100 : 140;
    const classes = useImageSliderItemStyles({
        ratioPercentage,
        imgUrl
    });
    const optional = {};

    if (onClick) {
        optional.onClick = onClick;
    }

    return <div className={classes.root}>
        <Paper className={classes.item} elevation={3} {...optional} />
    </div>;

};

const ImageSlider = ({slides}) => {
    return slides.length ? (
        <Carousel slidesToShow={2}>
            {slides.map(({id, title, img, width, heigth, onClick = null}) => (
                <ImageSliderItem
                    key={id}
                    imgUrl={img}
                    imgWidth={width}
                    imgHeight={heigth}
                    onClick={onClick}
                />
            ))}
        </Carousel>
    ) : null;
};

ImageSlider.propTypes = {
    slides: PropTypes.array
};

export default ImageSlider;