import React, {useState} from "react";
import {Button, Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '1rem 1rem',
    },
    fields: {
        padding: '1rem 0'
    }
}));

const UserLoginForm = ({onFormSubmit, errorMessage}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [loginValue, setLoginValue] = useState({});
    const [buttonEnabled, setButtonEnabled] = useState(true);

    const handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        setLoginValue(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setButtonEnabled(false); // Disabilito il bottone di login
        onFormSubmit(loginValue);
    };

    return (
        <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="username"
                        name="username"
                        label={t('UserLoginForm.form.username')}
                        type="text"
                        className={classes.fields}
                        fullWidth
                        value={loginValue.username || ''}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="password"
                        name="password"
                        label={t('UserLoginForm.form.password')}
                        type="password"
                        className={classes.fields}
                        fullWidth
                        value={loginValue.password || ''}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} className={classes.fields}>
                    <Button type="submit" disabled={!buttonEnabled}>
                        {t('UserLoginForm.accountLogin')}
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.fields}>
                    <Button component={Link} to="/resetpassword">
                        {t('UserLoginForm.accountRecover')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <p>
                        {errorMessage}
                    </p>
                </Grid>
            </Grid>
        </form>
    );
};

UserLoginForm.displayName = 'UserLoginForm';
export default UserLoginForm;
