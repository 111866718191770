import React, {useState} from "react";
import {Button, Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '1rem 1rem',
    },
    fields: {
        padding: '1rem 0'
    }
}));

export default ({onFormSubmit, errorMessage}) => {
    const classes = useStyles();
    const [loginValue, setLoginValue] = useState({});

    const handleInputChange = (event) => {
        event.preventDefault();

        const target = event.target;
        setLoginValue(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        onFormSubmit(loginValue);
    };

    return (
        <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        className={classes.fields}
                        fullWidth
                        value={loginValue.username || ''}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} className={classes.fields}>
                    <Button type="submit">
                        Recupera la Password
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <p>
                        {errorMessage}
                    </p>
                </Grid>
            </Grid>
        </form>
    );
}
