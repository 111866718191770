import {IconButton} from "@material-ui/core";
import React from "react";
import {withRouter} from "react-router-dom";

const BackButton = ({history}) => {
    return (
        <IconButton onClick={() => {
            history.push('/catalog')
        }}>
            <img alt="back" srcSet='images/ico_back@2x.png 2x'/>
        </IconButton>
    );
};

export default withRouter(BackButton);