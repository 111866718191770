const B2B_API_URL = 'https://sfa.cafenoir.it/DesktopModules/SincroSFAws/WebAppal.aspx';

export default {
    getHomeUrl(userKey) {
        return `${B2B_API_URL}?key=${userKey}`;
    },
    getProductUrl(userKey, productName, b2bLinea, b2bTipo) {
        console.log(productName, b2bLinea, b2bTipo);
        return `${B2B_API_URL}?key=${userKey}&td=${b2bTipo === 'stock' ? 'S' : 'R'}&p=${b2bLinea}`;
    }
}
