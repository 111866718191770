import React, {useEffect, useState} from "react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import VideoSlider from "../components/carousels/VideoSlider";
import ImageSlider from "../components/carousels/ImageSlider";
import CatalogList from "../components/CatalogList";
import {Divider, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import Slider from "../components/Slider";
import {
    getAgentEmail,
    getAgentId, getUser,
    getUserId,
    getUserKey, getUserType,
    isUserAgent,
    isUserCustomer,
    isUserLogged
} from "../contexts/UserContext";
import SliderAPI from "../utility/SliderAPI";
import b2bUtil from "../utility/b2bUtil";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {getActualLanguage, useLanguage} from "../contexts/LanguageContext";
import UserInfoLight from "../components/UserInfoLight";
import {download} from "../utility/downloadUtil";

const SEASON_DATA_QUERY = gql`
    query GetSeasonData($seasonSlug: ID!) {
        season (id: $seasonSlug, idType: SLUG) {
            slug,
            name,
            description,
            videos(first: 100, after: null) {
                nodes {
                    url_video
                }
            }
            advs(first: 100, after: null) {
                nodes {
                    id,
                    title,
                    featuredImage {
                        node {
                            id
                            sourceUrl
                        }
                    }
                }
            }
            promos(first: 100, after: null) {
                nodes {
                    id,
                    title,
                    featuredImage {
                        node {
                            id
                            sourceUrl
                        }
                    }
                }
            }
            catalogs(first: 100, after: null) {
                nodes {
                    id,
                    title,
                    file_pdf
                }
            }
        }
    }
`;

const convertGraphqlSeasonData = (data) => {
    return {
        name: data.season.name,
        description: data.season.description,
    };
}

const convertGraphqlVideoData = (data) => {
    return data.season.videos.nodes.map((video) => ({
        url: video.url_video,
    }));
}

const convertGraphqlAdvData = (data) => {
    return data.season.advs.nodes.map((adv) => ({
        id: adv.id,
        title: adv.title,
        img: adv.featuredImage.node.sourceUrl,
    }));
}

const convertGraphqlPromoData = (data) => {
    return data.season.promos.nodes.map((promo) => ({
        id: promo.id,
        title: promo.title,
        img: promo.featuredImage.node.sourceUrl,
    }));
}

const convertGraphqlCatalogData = (data) => {
    return data.season.catalogs.nodes.map((catalog) => ({
        id: catalog.id,
        title: catalog.title,
        name: catalog.title, // Da rimuovere
        url: catalog.file_pdf
    }));
}

const convertSlidersData = (data) => {
    return data.map((sliderGroup) => {
        const sliderGroupName = sliderGroup.name;
        const sliders = sliderGroup.sliders.map(slider => {
            const sliderId = slider.id;
            const sliderName = slider.name;
            const sliderWidth = slider.size.width;
            const sliderHeigth = slider.size.heigth;
            const sliderImg = slider.slides[0].link;
            return {
                id: sliderId,
                title: sliderName,
                img: sliderImg,
                width: sliderWidth,
                heigth: sliderHeigth,
                slides: slider.slides,
            }
        });
        return {
            sliderGroupName,
            sliders,
        }
    });
};

const convertGraphqlData = (data) => {
    /*
     * Estraggo i dati
     */
    const season = convertGraphqlSeasonData(data);
    const videos = convertGraphqlVideoData(data);
    const advs = convertGraphqlAdvData(data);
    const promos = convertGraphqlPromoData(data);
    const catalogs = convertGraphqlCatalogData(data);
    return {season, videos, advs, promos, catalogs};
}

const useSeasonCatalogStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#f7f7f7',
        padding: '0 3rem',
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
    },

    sliderContainer: {
        margin: '5rem 0 5rem 5rem',
    },

    seasonDivider: {
        marginTop: '1rem',
    },
}));

const SeasonCatalog = ({user, seasonSlug, sendNotify}) => {
    const {t} = useTranslation();
    const [language, ] = useLanguage();
    const {path} = useRouteMatch();
    const history = useHistory();
    const classes = useSeasonCatalogStyles();

    const {loading, error, data} = useQuery(SEASON_DATA_QUERY, {
        variables: {
            seasonSlug
        }
    });

    const [loadingSlider, setLoadingSlider] = useState(false);
    const [sliderGroupsData, setSliderGroupsData] = useState([]);
    const [userProductIds, setUserProductIds] = useState([]);

    useEffect(() => {
        (async () => {
            setLoadingSlider(true);
            const data = await Promise.all([
                SliderAPI.getSliders(getUserType(user), getActualLanguage(language), seasonSlug),
                SliderAPI.getUserProductIds(getUserId(user)),
            ]);
            setSliderGroupsData(data[0]);
            setUserProductIds(data[1].map(userProductId => (userProductId.hotspot)));
            setLoadingSlider(false);
        })();
    }, [seasonSlug, user, language]);

    /*
    // Restituisce null quando si passa da una pagina questa
    const {loading: loadingSlider, error: errorSlider, data: dataSlider} =
        useCatalogSlider(seasonSlug, user);
    */

    if (loading) {
        return null;
    }

    if (error) {
        return null;
    }


    if (loadingSlider) {
        return null;
    }

    /*
    if (errorSlider) {
        return null;
    }
    */

    if (data === null || sliderGroupsData === null || userProductIds === null) {
        return null;
    }

    const {season, videos, advs, catalogs, promos} = convertGraphqlData(data);
    const sliderGroups = convertSlidersData(sliderGroupsData);

    const userPart = (
        <UserInfoLight user={getUser(user)} userB2bLink={
            (isUserAgent(user) || isUserCustomer(user)) ? b2bUtil.getHomeUrl(getUserKey(user)) : null
        }/>
    );
    const seasonPart = (
        <Grid item>
            <Typography variant="h5">
                {season.name}
            </Typography>
            <Typography>
                {season.description}
            </Typography>
            <Divider className={classes.seasonDivider}/>
        </Grid>
    );
    const slidersPart = sliderGroups.map((sliderGroup, idx) => (
        <Grid item container key={idx}>
            <Grid item>
                <Typography>{sliderGroup.sliderGroupName}</Typography>
            </Grid>
            <ImageSlider
                slides={sliderGroup.sliders.map(slider => ({
                    ...slider,
                    onClick() {
                        history.push(`/catalog/slider/${idx}/${slider.id}`);
                    }
                }))}
            />
        </Grid>
    ));
    const videoPart = videos.length ? (
        <Grid item container>
            <Grid item>
                <Typography className={classes.title} variant='subtitle1'>
                    {t('VideosPreview.headerMessage')}
                </Typography>
            </Grid>
            <VideoSlider
                slides={videos}
            />
        </Grid>
    ) : null;
    const catalogPart = catalogs.length ? (
        <Grid item container>
            <Grid item>
                <Typography className={classes.title} variant='subtitle1'>
                    {t('CatalogList.headerMessage')}
                </Typography>
            </Grid>
            <CatalogList
                list={catalogs}
            />
        </Grid>
    ) : null;
    const advPart = (advs.length) ? (
        <Grid item container>
            <Grid item>
                <Typography className={classes.title} variant='subtitle1'>
                    {t('CatalogPage.ImageCarousel.ADV.headerMessage')}
                </Typography>
            </Grid>
            <ImageSlider
                slides={advs.map((adv => ({
                    ...adv,
                    onClick() {
                        download(adv.img);
                    }
                })))}
            />
        </Grid>
    ) : null;
    const promoPart = (promos.length) ? (
        <Grid item>
            <Grid item>
                <Typography className={classes.title} variant='subtitle1'>
                    {t('CatalogPage.ImageCarousel.Promo.headerMessage')}
                </Typography>
            </Grid>
            <ImageSlider
                slides={promos}
            />
        </Grid>
    ) : null;

    const videoSlides = (getActualLanguage(language) === 'de') ? [
        { // De
            url: "https://youtu.be/l5H8sBhcDRc"
        },
        {
            url: "https://youtu.be/KM_gagBRj8I",
        },
        {
            url: "https://youtu.be/Tf74GUFR8NU",
        }
    ] : [
        { // It
            "url": "https://youtu.be/9KNqeR42mKU"
        },
        {
            "url": "https://youtu.be/ug2otmV123Y"
        },
        {
            "url": "https://youtu.be/BmPAeYXgKS8"
        }
    ];
    const videoTutorialPart = (
        <Grid item container>
            <Grid item>
                <Typography className={classes.title} variant='subtitle1'>
                    {t('VideosPreview.headerTutorialMessage')}
                </Typography>
            </Grid>
            <VideoSlider
                slides={videoSlides}
            />
        </Grid>
    );

    const isAgentOrCustomer = isUserAgent(user) || isUserCustomer(user);

    const handleClickHotspot = (sliderId, slideId, hotspotId, famiglia) => {
        if (isUserLogged(user)) {
            const userId = getUserId(user);
            const agenteId = getAgentId(user);
            const agentEmail = getAgentEmail(user);

            if (userProductIds.indexOf(hotspotId) === -1) { // Aggiungo
                SliderAPI
                    .setUserProductId(sliderId, slideId, userId, hotspotId, famiglia, agenteId, agentEmail)
                    .then((data) => {
                        sendNotify('Prodotto Aggiunto alla Wishlist')
                        setUserProductIds(
                            prevUserProductIds => {
                                console.log(prevUserProductIds, hotspotId);
                                return [...prevUserProductIds, hotspotId];
                            }
                        );
                    });
            } else { // Rimuovo
                SliderAPI
                    .removeUserProductId(sliderId, slideId, userId, hotspotId, famiglia, agenteId, agentEmail)
                    .then((data) => {
                        sendNotify('Prodotto Rimosso dalla Wishlist');
                        setUserProductIds(
                            prevUserProductIds => prevUserProductIds.filter(
                                userProductId => userProductId !== hotspotId)
                        )
                    });
            }
        } else {
            console.log('Not logged');
        }
    };

    const handleClickProduct = isAgentOrCustomer ?
        (famiglia, b2bLinea, b2bTipo) => {
            window.open(b2bUtil.getProductUrl(getUserKey(user), famiglia, b2bLinea, b2bTipo));
        } :
        () => { // APPUSER
            // anche se riuscisse a cliccare su un prodotto non può fare nulla
        };

    return (
        <Switch>
            <Route exact path={path}>
                <Header/>
                <Grid
                    container direction="column" spacing={5}
                    className={classes.root}>
                    {userPart}
                    {seasonPart}
                    {slidersPart}
                    {videoPart}
                    {catalogPart}
                    {advPart}
                    {promoPart}
                    {videoTutorialPart}
                </Grid>
                <Footer/>
            </Route>
            <Route
                exact
                path={`${path}/slider/:sliderCollectionId/:sliderId`}
                render={(routeProps) => {
                    const {sliderCollectionId, sliderId} = routeProps.match.params;
                    const sliderGroup = sliderGroups[parseInt(sliderCollectionId)];
                    const slides = sliderGroup.sliders.find(slider => slider.id === parseInt(sliderId));

                    return (
                        <Slider
                            isAgentOrCustomer={isAgentOrCustomer}
                            slider={slides}
                            userProductIds={userProductIds}
                            handleClickHotspot={handleClickHotspot}
                            handleB2bAction={handleClickProduct}
                        />
                    );
                }}
            />
        </Switch>
    );
}

export default SeasonCatalog;