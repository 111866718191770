const API_URL =
    (process.env.NODE_ENV === 'test') ?
        'http://b2bdirectws.httdev.it/wp-json/htt/v1' :
        (process.env.NODE_ENV === 'production') ?
            '//managewa.cafenoir.it/wp-json/htt/v1' :
            'http://b2bdirectws.httdev.it/wp-json/htt/v1';

export default {
    register (data) {
        return fetch(`${API_URL}/user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...data
            }),
        }).then(res => {
            if (!res.ok) { // Se c'e' stato un'errore
                if (res.status === 400 || res.status === 404) {
                    return res.json().then((data) => {
                        return Promise.reject(
                            data.message
                        );
                    });
                } else {
                    return Promise.reject(
                        res.statusText
                    );
                }
            } else {
                return res.json(); // E' una Promise
            }
        });
    },
    confirm (token) {
        return fetch(`${API_URL}/user/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'c': token
            }),
        }).then(res => {
            if (!res.ok) { // Se c'e' stato un'errore
                if (res.status === 400 || res.status === 404) {
                    return res.json().then((data) => {
                        return Promise.reject(
                            data.message
                        );
                    });
                } else {
                    return Promise.reject(
                        res.statusText
                    );
                }
            } else {
                return res.json(); // E' una Promise
            }
        });
    },
    login (username, password) {
        return fetch(`${API_URL}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: username,
                password: password
            }),
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(
                    new Error(res.statusText)
                );
            } else {
                return res.json();
            }
        });
    },
    reset (username) {
        return fetch(`${API_URL}/user/forgotten`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: username,
            }),
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(
                    new Error(res.statusText)
                );
            } else {
                return res.json();
            }
        });
    }
}
