import React, {useContext, useState} from "react";

export const SeasonContext = React.createContext(undefined);

export const useSeason = () => {
    const context = useContext(SeasonContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a SeasonProvider');
    }
    return context;
}

export const SeasonProvider = ({children}) => {
    const [season, setSeason] = useState('ss22');

    const data = {
        current: season,
        list: [
            {
                code: 'fw21-22',
                name: 'Fall/Winter 21-22',
            },
            {
                code: 'ss21',
                name: 'Spring/Summer 21',
            },
            {
                code: 'ss22',
                name: 'Spring/Summer 22',
            },
            {
                code: 'tutorial',
                name: 'Video Tutorial: Dropshipping - Shop Digitale',
            }
        ],
        change(season) {
            setSeason(season);
        }
    };

    return (
        <SeasonContext.Provider value={data}>
            {children}
        </SeasonContext.Provider>
    );
}
