import React, {useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Grid, Paper, useMediaQuery} from "@material-ui/core";
import {Link} from "react-router-dom";
import UserResetPasswordForm from "../components/UserResetPasswordForm";
import {userResetPassword, useUser} from "../contexts/UserContext";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    heroImageBrand: {
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    heroImagePlaceholder: {
        width: '100%',
    },
    paper: {
        padding: '2rem',
        margin: '0 auto',
    }
}));

export default () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [loginErrorValue, setLoginErrorValue] = useState(null);
    const [user, userAction] = useUser();

    const handleLogin = (loginValue) => {
        userResetPassword(user, userAction, loginValue.username)
            .then(() => {
                setLoginErrorValue(`Ti e' stata inviata una mail con la tua password`);
            }, err => {
                setLoginErrorValue(`${err.name} ${err.message}`);
            });
    };

    const backgroundImage = matches ?
        '/images/light_login_placeholder_hero_desktop@2x.jpg':
        '/images/light_login_placeholder_hero@2x.jpg';

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item>
                    <Link to="/">
                        <img className={classes.heroImageBrand}
                             srcSet="/images/logo_black.png 2x"
                             alt="logo" />
                    </Link>
                    <img className={classes.heroImagePlaceholder}
                         alt="account"
                         srcSet={`${backgroundImage} 2x`}/>
                </Grid>
                <Grid container item>
                    <Paper className={classes.paper}>
                        <Grid container direction="column">
                            <Grid item>
                                <strong>Recupera la password del tuo Account</strong>
                            </Grid>
                            <UserResetPasswordForm errorMessage={loginErrorValue} onFormSubmit={handleLogin}/>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
