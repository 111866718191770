import React, {useEffect, useRef, useState} from "react";
import {
    AppBar, Collapse,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar
} from "@material-ui/core";
import {Favorite, Menu} from "@material-ui/icons";
import {Link, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
    ChevronRight,
    Close,
    ExpandLess,
    ExpandMore,
} from "@material-ui/icons";
import ChangeLanguage from "../components/ChangeLanguage";
import {isUserAgent, isUserLogged, useUser, userLogout} from "../contexts/UserContext";
import {useTranslation} from "react-i18next";
import ChangeSeason from "../components/ChangeSeason";
import {useSeason} from "../contexts/SeasonContext";

const useStyles = makeStyles(() => ({
    root: {},
    appbar: {
        alignSelf: "flex-start",
    },
    toolbar: {
        position: "relative",
    },
    logo: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    divider: {
        backgroundColor: "white",
    },
    menuList: {
        paddingLeft: "2rem",
        paddingRight: "2rem",
    }
}));

const LanguageMenu = () => {
    const {t} = useTranslation();
    const [openLanguage, setOpenLanguage] = useState(false);

    return (
        <>
            <ListItem button onClick={() => {
                setOpenLanguage((old) => {
                    return !old
                })
            }}>
                <ListItemText primary={t('Header.Menu.Languages')}/>
                {openLanguage ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={openLanguage}>
                <List>
                    <ChangeLanguage render={({code, language, img, change}) => {
                        return (
                            <ListItem key={code} button onClick={() => {
                                change()
                            }}>
                                <ListItemIcon>
                                    <img alt={code} srcSet={`${img} 2x`}/>
                                </ListItemIcon>
                                <ListItemText primary={language}/>
                            </ListItem>
                        )
                    }}/>
                </List>
            </Collapse>
        </>
    )
};

const SeasonMenu = () => {
    const {current} = useSeason();
    const history = useHistory();
    const isFirstRun = useRef(true);

    // Se la stagione corrente viene cambiata vado nella dashboard
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        // Vado alla pagina di catalogo
        history.push('/catalog');

    }, [current, history]);

    return (
        <List>
            <ChangeSeason
                render={({code, name, change}) => {
                    const currentStyle = (current === code) ? {
                        textDecoration: "underline",
                    }: null;

                    return <ListItem key={code} button onClick={() => {
                        change()
                    }}>
                        <ListItemText primaryTypographyProps={{
                            variant: 'h5',
                            style: currentStyle,
                        }} primary={name}/>
                        <ChevronRight/>
                    </ListItem>
                }}
            />
        </List>
    );
};

const Header = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [user, userAction] = useUser();
    const {t} = useTranslation();

    const logged = isUserLogged(user);

    const seasonMenu = logged ? <SeasonMenu /> : null;

    const loggedMenu = logged ? (
        <>
            {/* Account */}
            <ListItem button onClick={() => {
                history.push('/account');
            }}>
                <ListItemIcon>
                    <img alt="account" srcSet="/images/menuIcons/burger_account@2x.png 2x"/>
                </ListItemIcon>
                <ListItemText primary={t('Header.Menu.Account')}/>
            </ListItem>
            {/* Mock User */}
            {isUserAgent(user) && (
                <ListItem button onClick={() => {
                    history.push('/choose');
                }}>
                    <ListItemIcon>
                        <img alt="account" srcSet="/images/menuIcons/burger_scegli_cliente@2x.png 2x"/>
                    </ListItemIcon>
                    <ListItemText primary={t('Header.Menu.MockUser')}/>
                </ListItem>
            )}
            {/* Wishlist */}
            <ListItem button onClick={() => {
                history.push('/wishlist')
            }}>
                <ListItemIcon>
                    <img alt="wishlist" srcSet="/images/menuIcons/burger_wishlist@2x.png 2x"/>
                </ListItemIcon>
                <ListItemText primary={t('Header.Menu.Wishlist')}/>
            </ListItem>
            {/* Logout */}
            {
            <ListItem button onClick={() => {
                userLogout(user, userAction)
            }}>
                <ListItemIcon>
                    <img alt="logout" srcSet="/images/menuIcons/burger_logout@2x.png 2x"/>
                </ListItemIcon>
                <ListItemText primary={t('Header.Menu.Logout')} />
            </ListItem>
            }
        </>
    ) : null;

    return (
        <>
            <AppBar position="sticky" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton color="inherit" onClick={() => {
                        setOpen(true)
                    }}>
                        <Menu/>
                    </IconButton>
                    <Link to="/" className={classes.logo}>
                        <img
                            className="img-fluid"
                            srcSet={"/images/logo.png 2x"}
                            alt="logo"/>
                    </Link>
                    <div style={{flexGrow: 1}}/>
                    <IconButton color="inherit" onClick={() => {
                        history.push('/wishlist')
                    }}>
                        <Favorite/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="temporary" open={open} onClose={() => {
                setOpen(false)
            }}>
                <div>
                    <IconButton color="inherit" onClick={() => {
                        setOpen(false)
                    }}>
                        <Close/>
                    </IconButton>
                </div>
                <List className={classes.menuList}>
                    {seasonMenu}
                    <Divider className={classes.divider}/>
                    {loggedMenu}
                    <Divider className={classes.divider}/>
                    <LanguageMenu/>
                </List>
                <Divider/>
            </Drawer>
        </>
    );
};

export default Header;