import React, {useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const LayoutStyles = makeStyles(() => ({
    root: {
        height: '100%',
    },
    contentWrapper: {
        flex: 1,
        overflow: "scroll",
    }
}));

const withLayout =
    (WrapperComponent, hideHeader = false, hideFooter = false) =>
        ({...props}) => {
            const classes = LayoutStyles();
            const [notifyMessage, setNotifyMessage] = useState(null);

            const closeNotify = (event, reason) => {
                if (reason === 'clickaway') {
                    return;
                }
                setNotifyMessage(null);
            }

            const sendNotify = (message) => {
                setNotifyMessage(message);
            };

            return (
                <Grid container direction="column" justify="space-between" className={classes.root}>
                    {!hideHeader && <Header/>}
                    <Grid container item className={classes.contentWrapper}>
                        <WrapperComponent sendNotify={sendNotify} {...props}/>
                    </Grid>
                    <Snackbar
                        open={notifyMessage}
                        autoHideDuration={3000}
                        message={notifyMessage}
                        onClose={closeNotify}
                        action={
                            <IconButton size="small" aria-label="close" color="inherit" onClick={closeNotify}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        }
                    />
                    {!hideFooter && <Footer/>}
                </Grid>
            )
        };

export default withLayout;