import {getLanguages, setActualLanguage, useLanguage} from "../contexts/LanguageContext";

const ChangeLanguage = ({render}) => {
    const [language, languageAction] = useLanguage();
    const languages = getLanguages();

    const changeLanguage = (code) => {
        return () => {
            setActualLanguage(language, languageAction, code);
        };
    };

    return languages.map(({code, language, img}) => {
        return render({code, language, img, change: changeLanguage(code)});
    });
}

export default ChangeLanguage;