const API_URL =
    (process.env.NODE_ENV === 'production') ?
        'https://managewa.cafenoir.it/wp-json/htt/v1' :
        'https://b2bdirectws.httdev.it/wp-json/htt/v1';

export default {
    getSliders(userType, lang, season = null) {
        return fetch(`${API_URL}/sliders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lang: lang,
                user_type: userType,
                season: season,
            }),
        }).then(res => res.json());
    },
    sendUserProductsReport(userId) {
        return fetch(`${API_URL}/wishlist/products/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
            }),
        })
            .then(res => res.json())
            .then((data) => {
                return data;
            });
    },
    getUserProductIds(userId) {
        return fetch(`${API_URL}/wishlist/products?userId=${userId}`, {
            method: 'GET',
        }).then(res => res.json());
    },
    setUserProductId(sliderId, slideId, userId, productId, famiglia, agenteId, agentEmail) {
        return fetch(`${API_URL}/wishlist/products`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                sliderId: sliderId,
                slideId: slideId,
                productId: productId,
                famiglia: famiglia,
                agenteId: agenteId,
                agenteEmail: agentEmail,
            }),
        })
            .then(res => res.json())
            .then((data) => {
                console.log(['set', userId, productId, data]);
                return data;
            });
    },
    removeUserProductId(sliderId, slideId, userId, productId, famiglia, agenteId, agentEmail) {
        return fetch(`${API_URL}/wishlist/products`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                sliderId: sliderId,
                slideId: slideId,
                productId: productId,
                famiglia: famiglia,
                agenteId: agenteId,
                agenteEmail: agentEmail,
            }),
        })
            .then(res => res.json())
            .then((data) => {
                console.log(['remove', userId, productId]);
                return data;
            });
    }
};
