import React from "react";
import {ButtonBase, Grid, Paper} from "@material-ui/core";
import FaceIcon from '@material-ui/icons/Face';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '1rem 0',
    },
    button: {
        width: '100%',
    },
    paper: {
        padding: '1rem 1rem',
    }
}));

export default ({codice, ragioneSociale, onCustomerItemClick}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ButtonBase className={classes.button} onClick={() => onCustomerItemClick(codice)}>
                <Paper component={Grid} container className={classes.paper}>
                    <Grid item xs={2}>
                        <FaceIcon/>
                    </Grid>
                    <Grid item xs={10}>
                        <p>{ragioneSociale}</p>
                    </Grid>
                </Paper>
            </ButtonBase>
        </div>
    )
};
