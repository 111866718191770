import React, {useState} from "react";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {userRegister, useUser} from "../contexts/UserContext";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        padding: '2.5rem 2.5rem',
        backgroundColor: '#f7f7f7',
    },
    formContainer: {
        margin: '1rem 0',
    }
}));

const registerReturnValueInitial = {
    error: false,
    message: ''
};

const RegisterPage = () => {
    const classes = useStyles();
    const [user, userAction] = useUser();
    const [registerValue, setRegisterValue] = useState({});
    const [registerReturnValue, setRegisterReturnValue] = useState(registerReturnValueInitial);
    const [step, setStep] = useState(0);

    const {t} = useTranslation();

    const handleInputChange = (event) => {
        event.preventDefault();

        const target = event.target;
        setRegisterValue(prevState => ({
            ...prevState,
            [target.name]: target.value
        }));

    };

    const handleSubmit = (event) => {
        event.preventDefault();

        userRegister(user, userAction, registerValue)
            .then(
                () => { // Se la registrazione ha avuto successo
                    setRegisterReturnValue({
                        error: false,
                        message: 'Registrazione Avvenuta, controlla la mail'
                    });
                },
                err => { // Se la registrazione e' fallita
                    setRegisterReturnValue({
                        error: true,
                        message: `${err.name} ${err.message}`
                    });
                }
            );


        //setRegisterValue({}); // Resetto la form
        setStep(0); // Resetto lo step
    };

    const steps = [
        [
            <TextField variant="outlined"
                       id="email"
                       name="email"
                       label={t('UserRegisterForm.form.username')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.email || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="nome"
                       name="nome"
                       label={t('UserRegisterForm.form.name')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.nome || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="cognome"
                       name="cognome"
                       label={t('UserRegisterForm.form.surname')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.cognome || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="password"
                       name="password"
                       label={t('UserRegisterForm.form.password')}
                       type="password"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.password || ''}
                       onChange={handleInputChange}
            />
        ],
        [
            <TextField variant="outlined"
                       id="insegna"
                       name="insegna"
                       label={t('UserRegisterForm.form.insegna')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.insegna || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="telefono"
                       name="telefono"
                       label={t('UserRegisterForm.form.phone')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.telefono || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="nazione"
                       name="nazione"
                       label={t('UserRegisterForm.form.nazione')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.nazione || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="provincia"
                       name="provincia"
                       label={t('UserRegisterForm.form.provincia')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.provincia || ''}
                       onChange={handleInputChange}
            />,
            <TextField variant="outlined"
                       id="localita"
                       name="localita"
                       label={t('UserRegisterForm.form.localita')}
                       type="text"
                       className={classes.fields}
                       fullWidth
                       value={registerValue.localita || ''}
                       onChange={handleInputChange}
            />,
        ]
    ];

    const isFirstStep = (step === 0);
    const isLastStep = ((step + 1) === steps.length);

    const actionBtnNext = (
        <Button onClick={(isLastStep) ? handleSubmit : () => {
            setStep(step + 1)
        }}>
            {(isLastStep) ? t('UserRegisterForm.sendRequest') : t('UserRegisterForm.nextStep')}
        </Button>
    );
    const actionBtnBack = (isFirstStep) ? '' : (
        <Button onClick={() => {
            setStep(step - 1)
        }}>
            {t('UserRegisterForm.prevStep')}
        </Button>
    );

    const message = (registerReturnValue.error) ?
        (
            <h3>{registerReturnValue.message}</h3>
        ) :
        (
            <h3>{registerReturnValue.message}</h3>
        );

    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h4">{step + 1}/{steps.length}</Typography>
                <Typography variant="h5">{t('UserRegisterForm.stepTitle')}</Typography>
            </div>
            <Grid container direction="column" spacing={2}>
                {steps[step].map((input, idx) => {
                    return (
                        <Grid className={classes.formContainer} item key={`${step}_${idx}`}>
                            {input}
                        </Grid>
                    );
                })}
                <Grid item>
                    {message}
                </Grid>
                <Grid item>
                    {actionBtnNext}
                </Grid>
                <Grid item>
                    {actionBtnBack}
                </Grid>
            </Grid>
        </div>

    );
};


RegisterPage.displayName = 'RegisterPage';
export default RegisterPage;
