import React from "react";
import {Button, Grid, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";




const useWelcomeDescriptionItem = makeStyles(() => ({
    container: {
        textAlign: "center",
    }
}));
const WelcomeDescriptionItem = ({img, label}) => {
    const classes = useWelcomeDescriptionItem();
    return (
        <Grid item container xs={12} sm={6} lg={3} justify="center">
            <Grid item className={classes.container}>
                <img alt={label} srcSet={`${img} 2x`}/>
                <Typography>
                    {label}
                </Typography>
            </Grid>
        </Grid>
    );
}

const welcomeStyles = makeStyles(() => ({
    heroBackground: (obj) => ({
        backgroundImage: `url(${obj.backgroundImage.img})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: obj.backgroundImage.paddingTop,
    }),
    heroImageBrand: {
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    contentContainer: {
        marginTop: '2rem',
        marginBottom: '2rem',
        minHeight: '300px',
    },
    contentLabel: {
        textAlign: 'center',
    },
    descriptionContainer: {
        padding: '4rem 0',
        backgroundColor: '#f7f7f7',
    },
    descriptionTitle: {
        marginBottom: '3rem',
    }
}));
const Welcome = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const {t} = useTranslation();


    const backgroundImage = matches ?
        {
            img: '/images/landing_hero_desktop@2x.jpg',
            paddingTop: '56.25%',
        } :
        {
            img: '/images/landing_hero@2x.jpg',
            paddingTop: '89.09%',
        };

    const classes = welcomeStyles({
        backgroundImage
    });

    return (
        <Grid container direction="column">
            <Grid item xs className={classes.heroBackground}>
                <img className={classes.heroImageBrand}
                     srcSet="/images/logo_black.png 2x"
                     alt="logo"/>
            </Grid>
            <Grid item container justify="center">
                <Grid
                    container item xs={8} sm={6} lg={4} xl={3} direction="column"
                    justify="space-around" className={classes.contentContainer}>
                    <Grid item>
                        <Typography variant="h5" className={classes.contentLabel}>
                            {t('Welcome.welcomeHeader1')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" className={classes.contentLabel}>
                            {t('Welcome.welcomeHeader2')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.contentLabel}>
                            {t('Welcome.welcomeMessage')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to='/access'>
                            {t('Welcome.welcomeButton')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.descriptionContainer}>
                <Grid item container direction="column">
                    <Grid item className={classes.descriptionTitle}>
                        <Typography variant="h5" className={classes.contentLabel}>
                            {t('Welcome.info.title')}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <WelcomeDescriptionItem img="/images/landing_ico_video@2x.png" label={t('Welcome.info.videoProducts')}/>
                        <WelcomeDescriptionItem img="/images/landing_ico_programma_acquisto@2x.png"
                                                label={t('Welcome.info.buyPrograms')}/>
                        <WelcomeDescriptionItem img="/images/landing_ico_novita@2x.png" label={t('Welcome.info.news')}/>
                        <WelcomeDescriptionItem img="/images/landing_ico_supportovendita@2x.png"
                                                label={t('Welcome.info.sellSupport')}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Welcome;

/*
import {Button, Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    heroImageBrand: {
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    heroImagePlaceholder: {
        width: '100%',
    },
    welcomeInfoWrapper: {
        backgroundColor: '#f7f7f7',
        padding: '3rem 0',
    },
    welcomeInfoContainer: {
        padding: '2rem 0',
        width: '75%',
        margin: '0 auto',
    },
    welcomeInfoItemContainer: {
        padding: '1rem 1rem',
    },
    welcomeInfoItemImg: {
        display: 'block',
        margin: '0 auto .7rem auto',
    },
    welcomeInfoItemP: {}
}));

export default () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const { t } = useTranslation();

    function renderWelcomeInfoItem (imageSrc, label) {
        return (
            <Grid item xs={6} sm={3} className={classes.welcomeInfoItemContainer}>
                <img className={classes.welcomeInfoItemImg} srcSet={`${imageSrc} 2x`} alt="video"/>
                <p className={classes.welcomeInfoItemP}>{label}</p>
            </Grid>
        );
    }

    const backgroundImage = matches ?
        '/images/landing_hero_desktop@2x.jpg' :
        '/images/landing_hero@2x.jpg';


    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid item className={classes.heroImageBrand}>
                        <Link to="/">
                            <img
                                 srcSet="/images/logo_black.png 2x"
                                 alt="logo"/>
                        </Link>
                    </Grid>
                    <img className={classes.heroImagePlaceholder}
                         alt="account"
                         srcSet={`${backgroundImage} 2x`}/>
                </Grid>

                <Grid item container>

                </Grid>

                <Grid item xs={12} container justify="center" className={classes.welcomeInfoContainer}>
                    <Grid item xs={8}>
                        <strong>{t('WelcomePage.welcomeHeader1')}</strong>
                        <h2>{t('WelcomePage.welcomeHeader2')}</h2>
                        <p>
                            {t('WelcomePage.welcomeMessage')}
                        </p>
                        <Button component={Link} to='/access'>
                            {t('WelcomePage.welcomeButton')}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item container justify="center" alignItems="center" xs className={classes.welcomeInfoWrapper}>
                    <Grid item xs={12}>
                        <strong>{t('WelcomePage.info.title')}</strong>
                    </Grid>
                    {renderWelcomeInfoItem('/images/landing_ico_video@2x.png', t('WelcomePage.info.videoProducts'))}
                    {renderWelcomeInfoItem('/images/landing_ico_programma_acquisto@2x.png', t('WelcomePage.info.buyPrograms'))}
                    {renderWelcomeInfoItem('/images/landing_ico_novita@2x.png', t('WelcomePage.info.news'))}
                    {renderWelcomeInfoItem('/images/landing_ico_supportovendita@2x.png', t('WelcomePage.info.sellSupport'))}
                </Grid>

            </Grid>
        </div>
    );
};
 */
