import React from "react";
import {useSeason} from "../contexts/SeasonContext";
import SeasonCatalog from "../fragments/SeasonCatalog";
import {useUser} from "../contexts/UserContext";

const Catalog = ({sendNotify}) => {
    const season = useSeason();
    const [user, ] = useUser();

    return (
        <SeasonCatalog user={user} seasonSlug={season.current} sendNotify={sendNotify}/>
    );
};

export default Catalog;