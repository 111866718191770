export const download = (path) => {
    fetch(path)
        .then(response => {
            response.blob().then(blob => {
                const basename = path.split('/').reverse()[0];
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = basename;
                a.click();
            });
        });
};
