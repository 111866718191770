import {useSeason} from "../contexts/SeasonContext";

const ChangeSeason = ({render}) => {
    const season = useSeason();

    const changeSeason = (code) => {
        return () => {
            season.change(code);
        }
    }

    return season.list.map((s) =>
        render({
            code: s.code,
            name: s.name,
            change: changeSeason(s.code)
        })
    );
};

export default ChangeSeason;