import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getAgentEmail, getAgentId, getUserId, isUserLogged, useUser} from "../contexts/UserContext";
import SliderAPI from "../utility/SliderAPI";
import CloseIcon from "@material-ui/icons/Close";
import {IconButton, Grid, Typography, Paper} from "@material-ui/core";
import Loader from "../components/Loader";
import BackButton from "../components/BackButton";
import {Share} from "@material-ui/icons";


const wishlistItemStyle = makeStyles(() => ({
    wishlistItemWrapper: {
        margin: '2rem',
    },
    wishlistItem: {
        borderRadius: '12px',
    },
    wishlistItemHeader: {
        padding: '0 1rem',
            borderBottom: '1px solid #e6e6e6',
    },
    wishlistItemContent: {
        padding: '1rem',
    },
}));

const WishlistItem = ({
                          sliderId, sliderName, slideId, hotspotId, hotspotName, famiglia,
                          onWishListItemRemove
                      }) => {
    const classes = wishlistItemStyle();
    return (
        <Paper className={classes.wishlistItemWrapper}>
            <Grid container direction="column" className={classes.wishlistItem}>
                <Grid container item direction="row" justify="space-between" alignItems="center" wrap="nowrap"
                      className={classes.wishlistItemHeader}>
                    <Typography variant="subtitle1">{sliderName}</Typography>
                    <IconButton onClick={() => onWishListItemRemove(sliderId, slideId, hotspotId, famiglia)}>
                        <CloseIcon color="inherit"/>
                    </IconButton>
                </Grid>
                <Grid item className={classes.wishlistItemContent}>
                    <Typography>
                        {famiglia}<br/>
                        {hotspotName}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
};

const wishlistNoItemStyle = makeStyles(() => ({
    wishlistNoItemContent: {
        padding: '2rem',
    }
}));

const WishlistNoItem = () => {
    const classes = wishlistNoItemStyle();
    return (
        <Grid container justify="center" alignItems="center" wrap="nowrap">
            <Grid item className={classes.wishlistNoItemContent}>
                <Typography>
                    Nessun elemento aggiunto alla wishlist
                </Typography>
            </Grid>
        </Grid>
    )
}

const useStyle = makeStyles(() => ({
    root: {
        backgroundColor: '#f7f7f7',
        width: '100%'
    },
    wishlistTitleWrapper: {
        padding: '2rem 1rem 0',
    },
}));

const Wishlist = ({sendNotify}) => {
    const classes = useStyle();

    const [userProductIds, setUserProductIds] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [user,] = useUser();

    const handleClickHotspot = (sliderId, slideId, hotspotId, famiglia) => {
        if (isUserLogged(user)) {
            const userId = getUserId(user);
            const agenteId = getAgentId(user);
            const agentEmail = getAgentEmail(user);

            SliderAPI
                .removeUserProductId(sliderId, slideId, userId, hotspotId, famiglia, agenteId, agentEmail)
                .then(() => {
                    sendNotify('Prodotto Rimosso dalla Wishlist');
                    setUserProductIds(
                        prevUserProductIds => prevUserProductIds.filter(
                            userProductId => userProductId.hotspot !== hotspotId)
                    )
                });
        } else {
            console.log('Not logged');
        }
    };

    const handleSendReport = () => {
        if (isUserLogged(user)) {
            SliderAPI
                .sendUserProductsReport(getUserId(user))
                .then(() => {
                    sendNotify('Report Inviato');
                });
        }
    };

    useEffect(() => {
        if (isUserLogged(user)) {
            SliderAPI.getUserProductIds(getUserId(user))
                .then(userProductIds => {
                    setUserProductIds(userProductIds);
                    setLoaded(true);
                });
        }
    }, [user]);

    if (!loaded)
        return <Loader/>

    const wishlistItems = userProductIds.map((userProduct) => (
        <WishlistItem
            key={userProduct.hotspot}
            sliderId={userProduct.slider_id}
            sliderName={userProduct.slider_name}
            slideId={userProduct.slide_id}
            hotspotId={userProduct.hotspot}
            hotspotName={userProduct.hotspot_name}
            famiglia={userProduct.famiglia}
            onWishListItemRemove={handleClickHotspot}
        />
    ));

    return (
        <div className={classes.root}>
            { /* Page Title*/}
            <Grid container direction="row" justify="space-between" alignItems="center"
                className={classes.wishlistTitleWrapper}>
                <BackButton/>
                <Typography variant="h4">
                    Wishlist
                </Typography>
                {(wishlistItems.length) ? (
                    <IconButton onClick={handleSendReport}>
                        <Share/>
                    </IconButton>
                ) : <div/>}
            </Grid>
            { /* Page Content */}
            {(wishlistItems.length) ? wishlistItems: <WishlistNoItem/>}
        </div>
    );
}

export default Wishlist;
