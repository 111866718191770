import React, {Suspense} from 'react';
import './App.scss';

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Access from "./pages/Access";
import RegisterPage from "./pages/RegisterPage";
import Welcome from "./pages/Welcome";
import Login from "./pages/LoginPage";

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {UserProvider} from "./contexts/UserContext";
import PrivateRoute from "./routes/PrivateRoute";
import AccountPage from "./pages/AccountPage";
import Wishlist from "./pages/Wishlist";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ChoosePage from "./pages/ChoosePage";
import ConfirmPage from "./pages/ConfirmPage";
import {LanguageProvider} from "./contexts/LanguageContext";
import withLayout from "./layouts/withLayout";
import Catalog from "./pages/Catalog";
import {SeasonProvider} from "./contexts/SeasonContext";


const theme = createMuiTheme({
    //shadows: ["none"],
    typography: {
        fontFamily: 'futura-pt',
        body1: {
            fontSize: '14px',
        },
        h4: {
            fontWeight: 'bold',
        },
        h5: {
            fontWeight: 'bold',
            fontSize: '20px',
        },
        h6: {
            fontWeight: 'bold',
        },
        /* I Titoli delle sezioni */
        subtitle1: {
            fontWeight: 'bold',
            fontSize: '18px',
        }
    },
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffffff',
        },
    },
    overrides: {
        MuiToolbar: {
            regular: {
                minHeight: '80px',
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: 'futura-pt',
                fontSize: '18px',
            }
        },
        MuiInputLabel: {
            root: {
                fontFamily: 'futura-pt',
            }
        },
        MuiInput: {
            root: {
                fontFamily: 'futura-pt',
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: 'white',
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                border: 0,
            }
        },
        MuiButton: {
            root: {
                fontSize: '12px',
                fontFamily: 'futura-pt',
            },
            text: {
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 'bold',
                minHeight: '3rem',
                width: '100%',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: 'white',
                    color: 'black',
                }
            },
        },
        MuiListItemIcon: {
            root: {
                justifyContent: "center",
                color: "inherit",
            }
        },
        MuiIconButton: {},
        MuiDrawer: {
            root: {},
            paper: {
                width: "100%",
                color: "white",
                backgroundColor: "black",
            }
        },
    },
    shape: {
        borderRadius: 12,
    }
});

function CafenoirB2bHome() {
    window.location.replace('https://b2b.cafenoir.it/');
    return null;
}

export default () => {
    const LoginWithLayout = withLayout(Login);
    const RegisterWithLayout = withLayout(RegisterPage, false, true);
    const CatalogWithLayout = withLayout(Catalog, true, true);
    const AccountWithLayout = withLayout(AccountPage);
    const ChooseWithLayout = withLayout(ChoosePage);
    const ConfirmWithLayout = withLayout(ConfirmPage);
    const WishlistWithLayout = withLayout(Wishlist);

    return (
        <Suspense fallback="loading">
            <LanguageProvider>
                <ThemeProvider theme={theme}>
                    <SeasonProvider>
                        <UserProvider>
                            <Router>
                                <Switch>
                                    {/* Ridirigo tutto al B2b di Cafenoir */}
                                    <Route path="/">
                                        <CafenoirB2bHome/>
                                    </Route>

                                    <Route exact path="/login">
                                        <LoginWithLayout/>
                                    </Route>
                                    <Route exact path="/register">
                                        <RegisterWithLayout/>
                                    </Route>
                                    <Route exact path="/access">
                                        <Access/>
                                    </Route>
                                    <Route exact path="/confirm/:token">
                                        <ConfirmWithLayout/>
                                    </Route>
                                    <Route exact path="/resetpassword">
                                        <ResetPasswordPage/>
                                    </Route>
                                    <Route exact path="/welcome">
                                        <Welcome/>
                                    </Route>
                                    <PrivateRoute exact path="/account">
                                        <AccountWithLayout/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/choose">
                                        <ChooseWithLayout/>
                                    </PrivateRoute>
                                    {/*
                                    <PrivateRoute exact path="/catalog/slider/:sliderCollectionId/:sliderId">
                                        <CatalogSliderWithLayout/>
                                    </PrivateRoute>
                                    */}
                                    <PrivateRoute path="/catalog">
                                        <CatalogWithLayout/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/wishlist">
                                        <WishlistWithLayout/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/">
                                        <Redirect to="/catalog"/>
                                    </PrivateRoute>
                                </Switch>
                            </Router>
                        </UserProvider>
                    </SeasonProvider>
                </ThemeProvider>
            </LanguageProvider>
        </Suspense>
    );
};
