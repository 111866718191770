import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Carousel from "./Carousel";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";

const useVideoSliderItemStyles = makeStyles(() => ({
    root: {
        padding: '10px 0',
    },
    playerContainer: {
        height: 0,
        paddingTop: "56.25%",
        position: "relative",
        borderRadius: "22px"
    },
    player: {
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "22px",
        overflow: "hidden"
    }
}));

const VideoSliderItem = ({ videoUrl }) => {
    const classes = useVideoSliderItemStyles();
    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.playerContainer}>
                <ReactPlayer
                    className={classes.player}
                    width="100%"
                    height="100%"
                    url={videoUrl}
                />
            </Paper>
        </div>
    );
};

const VideoSlider = ({ slides }) => {
    return slides.length ? (
        <Carousel slidesToShow={1}>
            {slides.map(({url}, idx) => (
                <VideoSliderItem
                    key={idx}
                    videoUrl={url}
                />
            ))}
        </Carousel>
    ) : null;
};

VideoSlider.propTypes = {
    slides: PropTypes.array
};

export default VideoSlider;
