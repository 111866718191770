import React from "react";
import {ButtonBase, Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {download} from "../utility/downloadUtil";
import ReactHtmlParser from 'react-html-parser';

const useCatalogItemStyles = makeStyles(() => ({
    paper: {
        padding: '1rem 2rem 1rem',
    }
}));
const CatalogItem = ({title, downloadLink}) => {
    const classes = useCatalogItemStyles();
    return (
        <ButtonBase onClick={() => download(downloadLink)}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Grid container justify="space-between" direction="row">
                        <Grid item container xs direction="row" alignItems="center">
                            <Typography variant='body1'>{ReactHtmlParser(title)}</Typography>
                        </Grid>
                        <Grid item>
                            <img alt="download" srcSet="/images/ico_download@2x.png 2x"/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </ButtonBase>
    );
};

const CatalogList = ({list}) => {
    return (
        <Grid item container direction="column" spacing={1}>
            {list.map((catalog, idx) => (
                <CatalogItem
                    key={idx}
                    title={catalog.name}
                    downloadLink={catalog.url}
                />
            ))}
        </Grid>
    );
}

export default CatalogList;
