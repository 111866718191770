import React from "react";
import {getUser, userLogout, useUser} from "../contexts/UserContext";
import UserInfo from "../components/UserInfo";

const AccountPage = () => {
    const [user, userAction] = useUser();

    return (
        <UserInfo user={getUser(user)} onLogout={() => userLogout(user, userAction)}/>
    );
};

AccountPage.displayName = 'AccountPage';
export default AccountPage;
