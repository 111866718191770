import React, {useReducer} from "react";
import AccessAPI from "../utility/AccessAPI";

const userTypes = {
    unregister: 'UNREGISTER',
    customer: 'CLIENTE',
    agent: 'AGENTE',
    appuser: 'APPUSER',
};

export const UserContext = React.createContext({});
export const UserActionContext = React.createContext({});

const userActionTypes =  {
    login: 'LOGIN',
    mock: 'MOCK',
    logout: 'LOGOUT'
};

const userInitialState = {
    userType: userTypes.unregister,
    userData: null,
    mockData: null,
};

const localStorageKey = 'b2bcafenoir';

const userInitialStateLazy = () => {
    return JSON.parse(localStorage.getItem(localStorageKey)) || userInitialState;
};

// DEVE RIMARENE UNA FUNZIONA PURA (SENZA ASYNC)
const userReducer = (state, action) => {
    switch (action.type) {
        case userActionTypes.login: {
            const newState = {
                ...state,
                ...action.payload
            };
            localStorage.setItem(localStorageKey, JSON.stringify(newState));
            return newState;
        }
        case userActionTypes.mock: {
            const newState = {
                ...state,
                ...action.payload
            };
            localStorage.setItem(localStorageKey, JSON.stringify(newState));
            return newState;
        }
        case userActionTypes.logout: {
            localStorage.clear();
            return userInitialState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export const UserProvider = ({children}) => {
    const [state, dispatch] = useReducer(userReducer, userInitialState, userInitialStateLazy);

    return (
        <UserContext.Provider value={state}>
            <UserActionContext.Provider value={dispatch}>
                {children}
            </UserActionContext.Provider>
        </UserContext.Provider>
    )
};

export const useUserState = () => {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
};

export const useUserAction = () => {
    const context = React.useContext(UserActionContext);
    if (context === undefined) {
        throw new Error('useUserAction must be used within a UserProvider');
    }
    return context;
};

export const useUser = () => {
    return [useUserState(), useUserAction()];
};

const extractUserPayload = (loginData) => {
    let userType, agent, key;

    switch (loginData.TipoUtente) {
        case 'CLIENTE': {
            userType = userTypes.customer;
            agent = loginData.ListaAgenti.Info_Agente;
            key = loginData.UserKey;
            break;
        }
        case 'AGENTE': {
            userType = userTypes.agent;
            agent = null;
            key = loginData.UserKey;
            break;
        }
        case 'APPUSER': {
            userType = userTypes.appuser;
            agent = null;
            key = null;
            break;
        }
        default: {
            userType = userTypes.unregister;
            break;
        }
    }

    return {
        userType: userType,
        userData: {
            id: loginData.Username,
            name: loginData.Nominativo,
            info: loginData.ListaClienti.Info_Cliente,
            agent: agent,
            key: key,
            customers: loginData.ListaClienti.Info_Cliente
        }
    }
};

export const userRegister = async (user, userAction, data) => {
    try {
        return await AccessAPI.register(data);
    } catch (error) {
        throw new Error(error);
    }
};

export const userConfirm = async (user, userAction, token) => {
    try {
        const data = await AccessAPI.confirm(token);
        return data.data;
    } catch (error) {
        throw new Error(error);
    }
};


export const userLogin = async (user, userAction, username, password) => {
    try {
        const loginData = await AccessAPI.login(username, password);
        userAction({
            type: userActionTypes.login,
            payload: extractUserPayload(loginData)
        });
    } catch (error) {
        throw new Error('Login Failed');
    }
};

export const userLogout = (user, userAction) => {
    userAction({
        type: userActionTypes.logout
    })
};

export const userResetPassword = async (user, userAction, username) => {
    try {
        return  await AccessAPI.reset(username);
    } catch (error) {
        throw new Error('Reset Password Failed');
    }
};

export const userMock = (user, userAction, mockUserId) => {
    // Cerco l'utente con il codice da simulare
    const mockedUser = user.userData.customers.find(
        customer => customer.Codice === mockUserId
    );

    if (mockedUser) { // Se ho trovato l'utente
        userAction({
            type: userActionTypes.mock,
            payload: {
                mockData: mockedUser,
            }
        })
    }
};


export const isUserAgentChoose = (user) => {
    if (user.userType === userTypes.agent) { // Se e' un agente deve avere scielto il cliente
        if (user.mockData === null) { // Se l'agente non ha scelto un cliente da impersonare
            return false;
        }
    }
    return true;
};

export const isUserLogged = (user) => {
    return user.userType !== userTypes.unregister;
};

export const isUserCustomer = (user) => {
    return user.userType === userTypes.customer;
};

export const isUserAgent = (user) => {
    return user.userType === userTypes.agent;
};

export const isUserAppUser = (user) => {
    return user.userType === userTypes.appuser;
};

export const getUser = (user) => {
    switch (user.userType) {
        case userTypes.appuser:
        case userTypes.customer: {
            return user.userData.info;
        }
        case userTypes.agent: {
            return user.mockData;
        }
        default: {
            return null;
        }
    }
};

export const getAgent = (user) => {
    if (user.userType === userTypes.customer) { // Se sono un customer
        if (user.userData.agent) {
            return user.userData.agent;
        }
    }
    return null;
};

export const getAgentEmail = (user) => {
    const agent = getAgent(user);
    return (agent) ? agent.Email : null;
};

export const getAgentCustomers = (user) => {
    return (user) ? user.userData.customers : null;
};

export const getAgentId = (user) => {
    const agent = getAgent(user);
    return (agent) ? agent.Codice : null;
};

export const getUserId = (user) => {
    switch (user.userType) {
        case userTypes.appuser:
        case userTypes.customer: {
            return user.userData.info.Codice;
        }
        case userTypes.agent: {
            return user.mockData.Codice
        }
        default: {
            return null;
        }
    }
};

export const getUserType = (user) => {
    return user.userType;
};

export const getUserKey = (user) => {
    switch (user.userType) {
        case userTypes.agent:
        case userTypes.customer: {
            return user.userData.key;
        }
        default: {
            return null;
        }
    }
};
