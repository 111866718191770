import React from "react";
import NukaCarousel from "nuka-carousel";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useCarouselStyles = makeStyles({
    arrow: {
        backgroundColor: "white",
        '&:hover': {
            color: "white",
            backgroundColor: "black",
        }
    },
});

const Carousel = ({slidesToShow, children}) => {
    const classes = useCarouselStyles();
    const slidesShow = slidesToShow;
    const slidesViewport = slidesShow + 0.25;

    const renderCenterLeftControls = ({previousSlide, currentSlide}) => {
        return currentSlide !== 0 ? (
            <IconButton onClick={previousSlide} classes={{root: classes.arrow}}>
                <ChevronLeft/>
            </IconButton>
        ) : null;
    };

    const renderCenterRightControls = ({
                                           nextSlide,
                                           slideCount,
                                           currentSlide
                                       }) => {
        //console.log({ currentSlide, slidesShow, slideCount });
        return currentSlide + slidesShow < slideCount ? (
            <IconButton onClick={nextSlide} classes={{root: classes.arrow}}>
                <ChevronRight/>
            </IconButton>
        ) : null;
    };

    return (
        <NukaCarousel
            cellSpacing={10}
            slidesToShow={slidesViewport}
            renderCenterLeftControls={renderCenterLeftControls}
            renderCenterRightControls={renderCenterRightControls}
            renderBottomCenterControls={null}>
            {children}
        </NukaCarousel>
    );
};

export default Carousel;