import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Grid, Button, useMediaQuery, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const accessStyles = makeStyles(() => ({
    heroBackground: (obj) => ({
        backgroundImage: `url(${obj.backgroundImage.img})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: obj.backgroundImage.paddingTop,
    }),
    heroImageBrand: {
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    contentContainer: {
        marginTop: '3rem',
        marginBottom: '3rem',
        height: '200px',
    },
    contentLabel: {
        textAlign: 'center',
    },
    contentDivider: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        '&:before, &:after' : {
            content: '""',
            flex: '1',
            borderBottom: '2px solid #c6c6c6',
        },
        '&:before': {
            marginRight: '0.5rem',
        },
        '&:after': {
            marginLeft: '0.5rem',
        },
    },
}));


const Access = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const {t} = useTranslation();

    const backgroundImage = matches ?
        {
            img: '/images/login_hero_desktop@2x.jpg',
            paddingTop: '56.25%',
        } :
        {
            img: '/images/login_hero@2x.jpg',
            paddingTop: '98.66%',
        };

    const classes = accessStyles({
        backgroundImage,
    });

    return (
        <Grid container direction="column">
            <Grid item xs className={classes.heroBackground}>
                <img className={classes.heroImageBrand}
                     srcSet="/images/logo_black.png 2x"
                     alt="logo"/>
            </Grid>
            <Grid item container justify="center">
                <Grid
                    container item xs={8} sm={6} lg={4} xl={3} direction="column"
                    justify="space-between" className={classes.contentContainer}>
                    <Grid item>
                        <Typography variant="h5" className={classes.contentLabel}>
                            {t('Access.welcomeHeader')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to="/login">
                            {t('Access.accountLogin')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.contentDivider}>
                            Oppure
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to="/register">
                            {t('Access.accountCreate')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Access;
