import React from "react";

import {ButtonBase, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        padding: '1rem 2rem'
    },
}));

const UserInfoLight = ({user, userB2bLink, t}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container justify='space-between'>
                <Grid item>
                    <Typography variant='h6' className={classes.p}>{t('UserInfoLight.WelcomeMessage')}</Typography>
                    <Typography variant='h4'>{user.RagioneSociale}</Typography>
                </Grid>
                {userB2bLink && (
                    <Grid item>
                        <ButtonBase target="_blank" href={userB2bLink} rel="noopener noreferrer">
                            <img alt="b2b access" srcSet={'/images/bt_accedi_al_b2b@2x.png 2x'}/>
                        </ButtonBase>
                    </Grid>
                )}
            </Grid>
        </div>
    )
};

export default withTranslation()(UserInfoLight);
