import React from 'react';
import ReactDOM from 'react-dom';


// Slick Carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import ApolloClient from 'apollo-boost';
import {ApolloProvider} from '@apollo/react-hooks';

import './i18n';

const client = new ApolloClient({
    uri: (process.env.NODE_ENV === 'production') ?
        'https://managewa.cafenoir.it/graphql' :
        'https://b2bdirectws.httdev.it/graphql',
});


ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

const onServiceWorkerUpdate = registration => {
    const waitingServiceWorker = registration && registration.waiting;

    if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
            if (event.target.state === "activated") {
                console.log('Reload new version');
                window.localStorage.clear();
                window.location.reload();
            }
        });
        waitingServiceWorker.postMessage({
            type: 'SKIP_WAITING'
        });
    }
};

const onServiceWorkerSuccess = registration => {
    console.log("registered app for offline use. details:", registration)
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register({
    onUpdate: onServiceWorkerUpdate,
    onSuccess: onServiceWorkerSuccess,
});
