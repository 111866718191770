import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import SlideImageStatus from "../components/SlideImageStatus";
import SlideImageActions from "../components/SlideImageActions";
import HotspotInfo from "../components/HotspotInfo";
import Hotspot from "../components/Hotspot";
import {MODE} from "../utility/SliderUtil";
import {useSwipeable} from "react-swipeable";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";


const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (orientation: landscape)': {
            flexDirection: 'row',
        },
    },
    slider: {
        flexGrow: 1,
        '@media screen and (orientation: landscape)': {
            flexGrow: 0,
            flexBasis: '60%'
        },
        overflowY: 'scroll',

    },
    sliderImageContainer: {
        position: 'relative',
    },
    sliderImage: {
        width: '100%'
    },
    container1: {},
    container2: {
        '@media screen and (orientation: landscape)': {
            flexGrow: 0,
            flexBasis: '40%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
    }
}));

const Slider = ({isAgentOrCustomer, slider, userProductIds, handleClickHotspot, handleB2bAction, history}) => {
    const classes = useStyle();
    const isLandscape = useMediaQuery('screen and (orientation: landscape)');

    const slidersLength = slider.slides.length;
    const [actualSliderIdx, setActualSliderIdx] = useState(0);
    const [mode, setMode] = useState(MODE.IMAGE);

    const handleNextSlider = () => {
        setActualSliderIdx(((actualSliderIdx) => ((actualSliderIdx + 1) % slidersLength)));
    };
    const handlerPrevSlider = () => {
        setActualSliderIdx(((actualSliderIdx) =>
                ((actualSliderIdx === 0) ?
                    (slidersLength - 1) :
                    (actualSliderIdx - 1))
        ));
    };

    const swipeableHandlers = useSwipeable({
        onSwipedLeft: handleNextSlider,
        onSwipedRight: handlerPrevSlider,
    });

    const handleActionClick = () => {
        setMode(prevMode => {
            if (prevMode === MODE.IMAGE) {
                return MODE.INFO;
            } else {
                return MODE.IMAGE;
            }
        })
    };

    if (!slider) { // Se ho trovato la slide
        return <h1>Nessuno Slider trovato</h1>
    }

    const container1 = isLandscape ? null : (

        <SlideImageStatus
            title={slider.title}
            subTitle={`${actualSliderIdx + 1}/${slidersLength}`}
            onActionClick={() => {
                history.push('/catalog');
            }}
        />
    );

    const container2 = isLandscape ? (
        <>
            <SlideImageStatus
                title={slider.title}
                subTitle={`${actualSliderIdx + 1}/${slidersLength}`}
                onActionClick={() => {
                    history.push('/catalog');
                }}
            />
            <SlideImageActions
                onActionClick={handleActionClick}
                onPrevActionClick={() => handlerPrevSlider()}
                onNextActionClick={() => handleNextSlider()}
                mode={mode}
                showMode={slider.slides[actualSliderIdx].hotspots.length !== 0}
            />
        </>
    ) : (
        <SlideImageActions
            onActionClick={handleActionClick}
            onPrevActionClick={() => handlerPrevSlider()}
            onNextActionClick={() => handleNextSlider()}
            mode={mode}
            showMode={slider.slides[actualSliderIdx].hotspots.length !== 0}
        />
    );


    const sliderContent = (mode === MODE.IMAGE) ? (
        <div className={classes.sliderImageContainer}>
            <img className={classes.sliderImage} src={slider.slides[actualSliderIdx].link} alt=""/>
            {slider.slides[actualSliderIdx].hotspots.map(hotspot => {
                    let hotspotImage, hotspotAction;

                    if (hotspot.type === 'b2b') {
                        hotspotImage = "/images/ico_lookbook_link_b2b@2x.png";
                        hotspotAction = () => {
                            handleB2bAction(hotspot.famiglia, hotspot.b2bLinea, hotspot.b2bTipo);
                        };

                        // Non mostro nulla se utente dell'app normale
                        if (!isAgentOrCustomer) {
                            return null;
                        }

                    } else {
                        if ((userProductIds.indexOf(hotspot.id) !== -1)) {
                            hotspotImage = "/images/lookbook_wishlist_on@2x.png";
                        } else {
                            hotspotImage = "/images/lookbook_wishlist_off@2x.png";
                        }
                        hotspotAction = () => {
                            handleClickHotspot(
                                slider.id,
                                slider.slides[actualSliderIdx].id,
                                hotspot.id,
                                hotspot.famiglia,
                            )
                        };
                    }

                    return <Hotspot
                        key={hotspot.id}
                        id={hotspot.id}
                        img={hotspotImage}
                        top={hotspot.top}
                        left={hotspot.left}
                        onClickHotspot={hotspotAction}
                    />
                }
            )}
        </div>
    ) : (
        <div className={classes.sliderInfoContainer}>
            {slider.slides[actualSliderIdx].hotspots.map(hotspot => {
                let hotspotImage, hotspotAction;

                if (hotspot.type === 'b2b') {
                    hotspotImage = "/images/ico_lookbook_link_b2b@2x.png";
                    hotspotAction = () => {
                        handleB2bAction(hotspot.famiglia, hotspot.b2bLinea, hotspot.b2bTipo);
                    };

                    // Non mostro nulla se utente dell'app normale
                    if (!isAgentOrCustomer) {
                        return null;
                    }

                } else {
                    if ((userProductIds.indexOf(hotspot.id) !== -1)) {
                        hotspotImage = "/images/lookbook_wishlist_on@2x.png";
                    } else {
                        hotspotImage = "/images/lookbook_wishlist_off@2x.png";
                    }
                    hotspotAction = () => {
                        handleClickHotspot(
                            slider.id,
                            slider.slides[actualSliderIdx].id,
                            hotspot.id,
                            hotspot.famiglia,
                        )
                    };
                }

                return <Grid container item key={hotspot.id}>
                    <HotspotInfo
                        img={hotspotImage}
                        text={hotspot.text}
                        famiglia={hotspot.famiglia}
                        desc={hotspot.desc}
                        onClickHotspot={hotspotAction}
                    />
                </Grid>
            })}
        </div>
    );

    return (
        <div className={classes.root}>
            <div className={classes.container1}>
                {container1}
            </div>
            <div className={classes.slider} {...swipeableHandlers}>
                {sliderContent}
            </div>
            <div className={classes.container2}>
                {container2}
            </div>
        </div>
    );
};

export default withRouter(Slider);