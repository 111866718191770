import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Grid, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
    },
    appBar: {
        padding: '.5rem 0',
    },
    statusContainer: {
        padding: '1rem 0'
    },
    title: {
    },
    subtitle: {
        textAlign: 'center',
        margin: '1rem 0 0',
    },
    closeButton: {
        position: 'absolute',
        right: '24px',
        top: '1rem',
    }
}));

export default ({onActionClick, title, subTitle}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="secondary" className={classes.appBar}>
                <Toolbar>
                    <Grid container justify="center">
                        <Grid item className={classes.statusContainer}>
                            <Typography variant='h5' className={classes.title}>
                                {title}
                            </Typography>
                            <Typography variant='subtitle1' className={classes.subtitle}>
                                {subTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                    <ButtonBase className={classes.closeButton} onClick={() => onActionClick()}>
                        <img alt="close" srcSet={'/images/ico_chiudi@2x.png 2x'}/>
                    </ButtonBase>
                </Toolbar>
            </AppBar>
        </div>
    );
}
