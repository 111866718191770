import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import BackButton from "./BackButton";


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
    userInfoP: {
        marginBottom: 0
    },
    gridUnderlined: {
        borderBottom: '1px solid black',
    },
    userInfoContainer: {
        padding: '1rem 2rem',
    },
    userInfoImageWrapper: {
        position: 'relative'
    },
    userInfoInnerImage: {
        width: '100%',
    },
    userInfoInnerText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}));

const UserInfo = ({user, onLogout, t}) => {
    const classes = useStyles();

    function renderUserInfoImage (title) {
        return (
            <Grid item xs={12}>
                <div className={classes.userInfoImageWrapper}>
                    <img className={classes.userInfoInnerImage}
                         alt="profile-background"
                         srcSet="/images/area_personale_hero@2x.jpg 2x"/>
                    <h2 className={classes.userInfoInnerText}>{title}</h2>
                </div>
            </Grid>
        )
    }

    function renderUserInfoBox (label, value, underline = true, spacing = 12) {
        return (
            <Grid item xs={spacing} className={(underline) ? classes.gridUnderlined : null}>
                <p className={classes.userInfoP}>{label}</p>
                <strong>{value}</strong>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <BackButton />
            <Grid container>
                {renderUserInfoImage(user.RagioneSociale)}
                <Grid item container className={classes.userInfoContainer}>
                    <Grid item container className={classes.gridUnderlined}>
                        {renderUserInfoBox('Codice', user.Codice, false, 6)}
                        {renderUserInfoBox('Partiva Iva', user.PartitaIVA, false, 6)}
                    </Grid>
                    {renderUserInfoBox('Email', user.Email)}
                    {renderUserInfoBox('Ragione Sociale', user.RagioneSociale)}
                </Grid>
                <Grid item xs={12} className={classes.userInfoContainer}>
                    <Button onClick={() => onLogout()}>Logout</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default withTranslation()(UserInfo);
