import React, {createContext, useContext, useReducer} from 'react';
import i18next from 'i18next';

const LanguageStateContext = createContext({});
const LanguageActionContext = createContext({});

const LanguageActionTypes = {
    set: "SET"
};

const LanguageInitValue = {
    lang: 'it'
};

const LanguageI18nMapper = {
    it: {
        code: 'it',
        language: 'Italiano',
        img: '/images/ico_lingua_ita@2x.png'
    },
    //EN: 'en',
    de: {
        code: 'de',
        language: 'Deustch',
        img: '/images/ico_lingua_de@2x.png'
    },
};

const LanguageReducer = (state, action) => {
    switch (action.type) {
        case LanguageActionTypes.set: {
            console.log(state);
            return {
                ...state,
                lang: action.lang,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

export const useLanguage = () => {
    return [useLanguageState(), useLanguageAction()]
};

const useLanguageState = () => {
    const context = useContext(LanguageStateContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

const useLanguageAction = () => {
    const context = useContext(LanguageActionContext);
    if (context === undefined) {
        throw new Error('useActionLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const LanguageProvider = ({children}) => {
    const [state, action] = useReducer(LanguageReducer, LanguageInitValue, undefined);

    return (
        <LanguageStateContext.Provider value={state}>
            <LanguageActionContext.Provider value={action}>
                {children}
            </LanguageActionContext.Provider>
        </LanguageStateContext.Provider>
    );
};

export const setActualLanguage = (languageState, languageAction, newLanguage) => {
    languageAction({
        type: LanguageActionTypes.set,
        lang: newLanguage,
    });
    //console.log(newLanguage);
    //console.log(LanguageI18nMapper);
    i18next.changeLanguage(LanguageI18nMapper[newLanguage].code);
};

export const getLanguages = () => {
    //console.log(i18next.language);
    return Object.values(LanguageI18nMapper);
};

export const getActualLanguage = (languageState) => {
    //console.log(i18next.language);
    // Prendo solo i primi 2 caratteri
    return languageState.lang;
    //return i18next.language.substring(0,2);
};
